import Splide from "@splidejs/splide";
import anime from "animejs";
import SmoothScroll from "smooth-scroll";
import * as Accordion from "accordion-js";
import * as ScrollMagic from "scrollmagic";
import { THEME } from "../constants/GOOGLE_THEME";

/**
 * Splide config object contains all the options that are needed
 * to initialize the hero fade effect.
 */
const splide_config = {
  type: "fade",
  perPage: 1,
  arrows: false,
  pagination: false,
  interval: 4500,
  autoplay: true,
  rewind: true,
  drag: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 1400,
};

/**
 * try catch for hero fade effect
 */
try {
  new Splide("#hero_slide", splide_config).mount();
} catch (e) {
  console.warn("Cannot find Splide List");
}

/**
 * Website animations
 *  - Nav bar
 *  - All scroll Animations
 */
try {
  // Add scrollmagic controller
  let controller = new ScrollMagic.Controller();

  /**
   * Navbar Background Animation
   */
  const navbar = anime({
    targets: ".nav-container",
    backgroundColor: ["transparent", "rgba(0, 0, 0, 0.75)"],
    "backdrop-filter": ["none", "blur(12px)"],
    elasticity: 100,
    easing: "easeInOutCubic",
    autoplay: false,
  });

  //
  let overlay_text_timeline = anime.timeline({ autoplay: true, loop: false });

  // Add animations
  let overlay_text_anime = {
    targets: [".big-text-title"],
    translateY: ["200", "0"],
    opacity: [0, 1],
    delay: 100,
    duration: 1000,
    direction: "alternate",
    easing: "easeOutExpo",
  };

  // Add animation to timeline
  overlay_text_timeline.add(overlay_text_anime);

  /**
   * Define the square animation
   */
  const overlay_hero_text = anime({
    targets: "#overlay-img",
    rotate: [0, 360],
    elasticity: 400,
    easing: "easeInOutCubic",
    autoplay: false,
  });

  /**
   * Big bold text animation
   */
  const str_text = anime({
    targets: ".st",
    right: ["0", "16rem"],
    elasticity: 100,
    easing: "easeInOutCubic",
    autoplay: false,
  });
  const dur_text = anime({
    targets: ".dr",
    left: ["0", "40%"],
    elasticity: 100,
    easing: "easeInOutCubic",
    autoplay: false,
  });
  const efi_text = anime({
    targets: ".ef",
    left: ["60%", "15%"],
    elasticity: 100,
    easing: "easeInOutCubic",
    autoplay: false,
  });

  /**
   * Big bold text animation
   */
  const p1_text = anime({
    targets: "#dry-van-text",
    right: ["0", "80%"],
    elasticity: 0,
    easing: "easeInOutCubic",
    autoplay: false,
  });
  const p2_text = anime({
    targets: "#reefer-van-text",
    left: ["0", "15%"],
    elasticity: 100,
    easing: "easeInOutCubic",
    autoplay: false,
  });
  const p3_text = anime({
    targets: "#flat-bed-text",
    right: ["0", "20%"],
    elasticity: 400,
    easing: "easeInOutCubic",
    autoplay: false,
  });

  /**
   * Add a scroll listener on the window object to
   * control animations based on scroll percentage.
   */
  window.onscroll = () => {
    navbar.seek((scrollPercent() / 2) * efi_text.duration);
  };

  // init controller

  // create a scene
  const scene1 = new ScrollMagic.Scene({
    triggerElement: "#home",
    duration: 3200, // the scene should last for a scroll distance of 100px
    offset: 320, // start this scene after scrolling for 50px
  })
    .addTo(controller)
    .on("progress", (event) => {
      overlay_hero_text.seek(event.progress * overlay_hero_text.duration);
    });

  const scene2 = new ScrollMagic.Scene({
    triggerElement: "#about-section",
    duration: 3600, // the scene should last for a scroll distance of 100px
    offset: -720, // start this scene after scrolling for 50px
  })
    .addTo(controller)
    .on("progress", (event) => {
      str_text.seek(event.progress * str_text.duration);
      dur_text.seek(event.progress * dur_text.duration);
      efi_text.seek(event.progress * efi_text.duration);
    });

  const scene3 = new ScrollMagic.Scene({
    triggerElement: "#products-section",
    duration: 7000, // the scene should last for a scroll distance of 100px
    offset: -200, // start this scene after scrolling for 50px
  })
    .addTo(controller)
    .on("progress", (event) => {
      p1_text.seek(event.progress * p1_text.duration);
      p2_text.seek(event.progress * p2_text.duration);
      p3_text.seek(event.progress * p3_text.duration);
    });
} catch (e) {
  console.warn("Cannot find AnimeJs");
}

try {
  new SmoothScroll('a[href*="#"]', {
    speed: 1600,
    speedAsDuration: true,
    easing: "easeInOutCubic",
    offset: 78,
  });
} catch (e) {
  console.warn("Cannot find smooth scroll");
}

try {
  new Accordion(".accordion-container", {
    collapse: true,
  }).openAll();
} catch (e) {}

/**
 * Calculate the scroll percentage position
 */
const scrollPercent = () => {
  const bodyST = document.body.scrollTop;
  const docST = document.documentElement.scrollTop;
  const docSH = document.documentElement.scrollHeight;
  const docCH = document.documentElement.clientHeight;

  return ((docST + bodyST) / (docSH - docCH)) * 100;
};

try {
  const nav_btn = document.querySelector("#get-quote-nav-js");
  const quote_btn = document.querySelector("#get-quote-dry-js");

  nav_btn.addEventListener("click", () => {
    const modal = document.querySelector(".quote-modal-wrapper");
    modal.style.opacity = 1;
    modal.style.transform = "translateX(0%)";
  });
  quote_btn.addEventListener("click", () => {
    const modal = document.querySelector(".quote-modal-wrapper");
    modal.style.opacity = 1;
    modal.style.transform = "translateX(0%)";
  });

  const close_btn = document.querySelector("#close-modal-btn-js");
  close_btn.addEventListener("click", () => {
    const modal = document.querySelector(".quote-modal-wrapper");
    modal.style.opacity = 0;
    modal.style.transform = "translateX(-100%)";
  });
} catch (e) {}

try {
  var main = new Splide("#main-slider", {
    type: "fade",
    heightRatio: 0.5,
    pagination: false,
    arrows: false,
    cover: true,
  });

  var thumbnails = new Splide("#thumbnail-slider", {
    rewind: true,
    fixedWidth: 104,
    fixedHeight: 58,
    isNavigation: true,
    gap: 10,
    focus: "center",
    pagination: false,
    cover: true,
    dragMinThreshold: {
      mouse: 4,
      touch: 10,
    },
    breakpoints: {
      640: {
        fixedWidth: 66,
        fixedHeight: 38,
      },
    },
  });

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();
} catch (e) {
  console.log(e)
}
